<template>
  <div style="color: #fff">{{ ErrorMsg }}</div>
</template>

<script>
import querystring from "querystring";
import { Login } from "@/api/index.js";

export default {
  data() {
    return {
      ErrorMsg: "",
      mDate1: "",
      mDate2: "",
      mCompany: "",
      mDept: "",
      mLine: "",
    };
  },

  mounted() {
    let par = location.hash;
    par = decodeURIComponent(par.substring(par.indexOf("?") + 1, par.length));
    let objUser = querystring.parse(par.replace("?pid=", "&pid="));
    console.log(objUser);
    this.$store.commit("setOpenID", objUser.pid);
    this.$store.commit("setFaceUrl", objUser.furl);

    this.mDate1 = objUser.date1;
    this.mDate2 = objUser.date2;
    this.mCompany = objUser.company;
    this.mDept = objUser.dept;
    this.mLine = objUser.line;

    if (objUser.pid != undefined && objUser.pid != "") {
      this.UserLogin(objUser.pid);
    }
  },

  methods: {
    UserLogin(_openid) {
      Login(
        {
          openId: _openid,
          userName: "",
          password: "",
          captcha: "",
          verKey: "",
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              this.$store.commit("setToken", ret.data.token);
              this.$store.commit("setUser", ret.data.user);
              this.$store.commit("setCompanys", ret.data.companys);
              this.$store.commit("setDepts", ret.data.depts);
              this.$store.commit("setGroup", ret.data.group);
              this.$store.commit("setInspector", ret.data.inspector);
              this.$store.commit("setPermission", ret.data.permission);
              this.$store.commit("setPlace", ret.data.place);
              this.$store.commit("setRoutes", ret.data.routes);
              this.$router.replace({
                path: "/wechat/missrecord",
                query: {
                  mDate1: this.mDate1,
                  mDate2: this.mDate2,
                  mCompany: this.mCompany,
                  mDept: this.mDept,
                  mLine: this.mLine,
                },
              });
            } else if (ret.code != 9) this.ErrorMsg = ret.message;
          } else this.ErrorMsg = this.$t("lang.login_fail");
        }
      );
    },
  },

  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#336666");
  },
  //#0184b2
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
};
</script>
